//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from "jquery";

export default {
  name: "CadastreModal",
  props: {
    history: Array,
  },
  methods: {
    hideHistory() {
      $('#modalCadastre').modal('hide');
    }
  }
}
